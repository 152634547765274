
.footerbody{
    display: flex;
    margin: 0;
    padding: 5rem 0 6rem 0;
    justify-content: space-evenly;
}
.logosection{
    max-width: 22rem;
    display: flex;
    flex-direction: column;
    
}
.companylogo{
    margin-bottom:1.3rem;
}
.companyName {
    margin: auto;
    height: 1.75rem;
    margin-bottom:1.5rem;
}
.list{
    border: none;
}
.list h5{
    
    color: #8a75f6;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}
.list ul{
    list-style-type: none;
    position: relative;
    right: 24px;
    max-width: 149px;
}
.list ul li{
    padding: 0.5rem 0;
}
.list ul li a{
    text-decoration: none;
    color:rgba(85, 64, 199, 0.85);
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 29.05px;
    transition:transform 0.2s ease-in-out;
    
}
.list ul li a:hover{
    color:#5536ef;
    transform: scale(1.05);
}
.footerMid{
    
    display: flex;
    justify-content: space-between;
}
.iconsDiv{
   margin-top:8px;
}
.dropdownButton,.dropdownButton:hover{
    background-color: #f8fbff;
    color: black;
    font-weight: 500;
    width: 13.5rem;
    height: 3rem;
    border: 2px solid #5640C9;
    border-radius: 20px;
}
.dropdownButton::after{
    margin-left: 5rem;
}
.bottomline hr{
    color: #5640c9;
    height: 0.5px;
    margin: 0;
}
/* .bottomline p{
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #240BAD; */





.bottomline div{
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
   
    line-height: 20px;
    color: #240BAD;
    background-color: #e3e0f4;
    padding:20px 0 10px 0;
    /* text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #240BAD; */
   
}
.bottomline div ul{
    box-sizing: border-box;
    /* display:flex;
    justify-content:space-evenly; */
    list-style: none;
    text-align:center;
}
.bottomline div ul li{
    margin-top:10px;
    font-size: 17px;
}

@media (max-width: 1100px){
    .footerbody{
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 720px) {
    .linkSection ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .list ul{
        display: grid;
        grid-template-columns: 1fr;
    }
}