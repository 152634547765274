#title {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 5%;
  margin-bottom: 2rem;
}

.text {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
}
#box1 {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 50px;
  box-sizing: border-box;
}

.button1 {
  background: #5540c7;
  border-radius: 50px;

  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  margin: 0.5rem;
  width: 98%;
}

.container2 {
  width: 70%;
  margin-left: 15%;
}

.box2 {
  background-color: aquamarine;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 25px;
  margin-top: 3rem;
}

#jobdesc1 {
  margin: 2rem;
}

.text2 {
  margin-top: 4rem;
  font-style: normal;
  color: black;
  font-size: 100%;
}

.button2 {
  background: #5540c7;
  border-radius: 50px;
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 150%;
  line-height: 3rem;
  margin-top: 20%;
  margin-left: 20%;
  color: #ffffff;
  display: inline;
}

.text3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 150%;
  line-height: 100%;
  color: #161515;
  margin-top: 5rem;
}

.text4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 150%;
  line-height: 100%;
  color: #161515;
}

#title2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 120%;
  line-height: 100%;
  text-decoration-line: underline;
  color: #000000;
}

.box3 {
  border: 1px solid #000000;
  border-radius: 20px;
  margin-bottom: 6rem;
  margin-top: 2rem;
}

.c1 {
  margin: 2rem;
}

.c2 {
  margin: 2rem;
  display: flex;
}

.button3 {
  background: #5540c7;
  border-radius: 20px;
  font-weight: 600;
  font-size: 150%;
  line-height: 3rem;
  margin-top: 1rem;
}

#title4 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 6rem;
  margin-left: 40%;
}
